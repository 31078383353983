.treetodo {
  background-color: #1e1e1e;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px;
}

.disable-user-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.node-init {
  cursor: pointer;
}
.node-init circle {
  stroke: #00aa00;
  stroke-width: 1px;
}
.node-init text {
  background-color: #444;
  fill: #f4f4f4;
  text-shadow: 0 1px 4px black;
}

/* cursor type of node */
.default-node,
.completed-node,
.working-node,
.pending-node,
.troubled-node {
  cursor: pointer;
  font: #f4f4f4;
}

.default-node,
.completed-node,
.working-node,
.pending-node,
.troubled-node text {
  fill: #f4f4f4;
  text-shadow: 0 1px 4px black;
}

.default-node circle {
  fill: #555555;
  stroke: #00aa00;
  stroke-width: 1px;
}

.completed-node circle {
  stroke: #00aa00;
  stroke-width: 1px;
  fill: #00aa00;
}
.working-node circle {
  stroke: #00aa00;
  stroke-width: 1px;
  fill: #ff8c00;
}
.pending-node circle {
  stroke: #00aa00;
  stroke-width: 1px;
  fill: #6666ff;
}
.troubled-node circle {
  stroke: #00aa00;
  stroke-width: 1px;
  fill: #ff0000;
}
.must-node circle {
  stroke: #00aa00;
  stroke-width: 1px;
  fill: red;
}

.solid-line {
  fill: none;
  stroke: #00aa00;
  stroke-width: 3.5px;
}
.dotted-line {
  fill: none;
  stroke: #77aa77;
  stroke-width: 3.5px;
  stroke-dasharray: 3.5px;
}
.discontinue-line {
  fill: none;
  stroke: #77aa77;
  stroke-width: 0.5px;
  stroke-dasharray: 3.5px;
}
